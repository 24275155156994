// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination, Swiper } from 'swiper/core';
import 'swiper/swiper-bundle.css';
// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);

// init Swiper:
var mySwiper = new Swiper('.swiper-container', {
	speed: 500,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
    },
    pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: true
	}
});

var swiper = new Swiper('.swiper-home-items', {
	slidesPerView: 3,
	spaceBetween: 10,
	// init: false,
	speed: 500,
	navigation: {
		nextEl: '.swiper-home-items-button-next',
		prevEl: '.swiper-home-items-button-prev'
    },
    pagination: {
		el: '.swiper-home-items-pagination',
		type: 'bullets',
		clickable: true
	},
	breakpoints: {
	  640: {
		slidesPerView: 3,
		spaceBetween: 20,
	  },
	  768: {
		slidesPerView: 4,
		spaceBetween: 40,
	  },
	  1024: {
		slidesPerView: 5,
		spaceBetween: 50,
	  },
	}
  });